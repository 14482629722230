import {
  ApolloCache,
  DataProxy,
  InMemoryCache,
  OperationVariables,
  Reference,
  StoreObject,
} from "@apollo/client";
import type {
  FieldSpecifier,
  Modifier,
  ModifierDetails,
  Modifiers,
  ToReferenceFunction,
} from "@apollo/client/cache/core/types/common";
import type { Cache } from "@apollo/client/cache/core/types/Cache";
import { storeKeyNameFromField } from "@apollo/client/utilities";
import { DocumentNode, FieldNode } from "graphql";
import { merge } from "lodash";
import { AssertionError } from "utils/assertions";
import client from "./client";
import { NodesConnection } from "./pagination";
import {
  getNodeAtPath,
  getOperationOrFragment,
  isFragmentDefinitionNode,
} from "./misc";

type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

/** A helper function that recursively merges new data with existing data for a
 * given query. */
export function patchCachedQuery<
  TData = unknown,
  TVariables extends OperationVariables = never,
>({
  data,
  ...options
}: DataProxy.Query<TVariables, TData> & {
  /** Either a partial data object in the same shape as that which the original
   * query returns, or a function that returns it. If a function is provided,
   * the existing data from the cache is passed as an argument; otherwise data
   * is merged with the existing data recursively. If this is `null` or
   * `undefined` the cache update is cancelled. */
  data:
    | RecursivePartial<TData>
    | ((existing: TData | null) => RecursivePartial<TData> | undefined | null)
    | undefined
    | null;
}) {
  if (!data) return;
  const existing = client.readQuery<TData, TVariables>(options);
  let mergedData = {} as RecursivePartial<TData>;
  if (typeof data === "function") {
    const result = data(existing);
    if (!result) return;
    mergedData = result;
  } else {
    merge(mergedData, existing, data);
  }
  client.writeQuery({ ...options, data: mergedData as TData });
}

export function makeClearListFieldCache(fieldKey: string) {
  const modifier: Modifier<unknown> = (fieldValue, details) => {
    if ([fieldKey, `${fieldKey}List`].includes(details.fieldName)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return details.DELETE;
    }
    return fieldValue;
  };
  return modifier;
}

type ClearCachedTypeOptions = {
  onlyFields?: string[];
  options?: Pick<Cache.ModifyOptions, "broadcast" | "optimistic">;
  preserveFields?: string[];
};

export function makeClearCachedType(cache: ApolloCache<unknown>) {
  return function clearCachedTypes(
    typename: string,
    options: ClearCachedTypeOptions = {},
  ) {
    const { onlyFields, options: apolloOptions, preserveFields } = options;

    const cacheData = cache.extract();

    if (!cacheData || typeof cacheData !== "object") return;

    const refsToClear = Object.keys(cacheData).filter((k) =>
      k.startsWith(`${typename}:`),
    );

    if (!preserveFields?.length && !onlyFields) {
      return refsToClear.forEach((ref) => {
        cache.evict({ broadcast: false, ...apolloOptions, id: ref });
      });
    }

    return refsToClear.forEach((ref) => {
      cache.modify({
        broadcast: false,
        ...apolloOptions,
        id: ref,
        fields: (fieldValue: unknown, details) => {
          if (["id", "__typename"].includes(details.fieldName)) {
            return fieldValue;
          }

          if (onlyFields && !onlyFields.includes(details.fieldName)) {
            return fieldValue;
          }

          if (
            preserveFields?.length &&
            preserveFields.includes(details.fieldName)
          ) {
            return fieldValue;
          }

          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return details.DELETE;
        },
      });
    });
  };
}

export function makeClearNestedListFieldCache(cache: ApolloCache<unknown>) {
  const clearCachedTypes = makeClearCachedType(cache);
  return function clearNestedListFieldCache(
    typename: string,
    fieldKeys: string[],
    options?: Pick<Cache.ModifyOptions, "broadcast" | "optimistic">,
  ) {
    return clearCachedTypes(typename, {
      onlyFields: [...fieldKeys, ...fieldKeys.map((k) => `${k}List`)],
      options,
    });
  };
}

export function getStoreFieldName(
  cache: ApolloCache<unknown>,
  fieldSpec: FieldSpecifier,
  assertInMemoryCache = true,
) {
  if (!(cache instanceof InMemoryCache)) {
    if (assertInMemoryCache)
      throw new AssertionError(
        "Expected cache to be an instance of InMemoryCache",
      );
    return;
  }
  const { policies } = cache;
  return policies.getStoreFieldName(fieldSpec);
}

export type ModifyFieldOptions<T> = {
  cache: ApolloCache<unknown>;
  expectedStoreFieldName?: string;
  fieldName: string;
  id?: string;
  keyFieldName?: string;
  modifier: Modifier<T>;
  typename?: string;
};

export type ModifyFieldResult = {
  isSuccess: boolean;
  cacheId: string | undefined;
  fieldName: string;
  expectedStoreFieldName?: string;
};

export function modifyField<T>({
  cache,
  expectedStoreFieldName,
  fieldName,
  id,
  keyFieldName = "id",
  modifier,
  typename = "Query",
}: ModifyFieldOptions<T>): ModifyFieldResult {
  const cacheId = id
    ? cache.identify({ __typename: typename, [keyFieldName]: id })
    : undefined;

  const isSuccess = cache.modify({
    ...(cacheId ? { id: cacheId } : {}),
    fields: {
      [fieldName]: (existing: T | undefined, details) => {
        if (
          !existing ||
          (expectedStoreFieldName &&
            details.storeFieldName !== expectedStoreFieldName)
        ) {
          return existing;
        }
        return modifier(existing, details);
      },
    },
  });

  return { isSuccess, cacheId, fieldName, expectedStoreFieldName };
}

export type ModifyFieldWithSpecOptions<T> = Omit<
  ModifyFieldOptions<T>,
  "expectedStoreFieldName" | "fieldName"
> & {
  fieldSpec: FieldSpecifier;
};

export function modifyFieldWithSpec<T>({
  fieldSpec: providedFieldSpec,
  typename = "Query",
  ...options
}: ModifyFieldWithSpecOptions<T>) {
  const fieldSpec: FieldSpecifier = {
    typename,
    ...providedFieldSpec,
  };
  const expectedStoreFieldName = getStoreFieldName(options.cache, fieldSpec);
  if (!expectedStoreFieldName) return;
  return modifyField({
    ...options,
    typename,
    expectedStoreFieldName,
    fieldName: fieldSpec.fieldName,
  });
}

export type ModifyFieldWithNodeOptions<T> = Omit<
  ModifyFieldOptions<T>,
  "expectedStoreFieldName" | "fieldName"
> & {
  fieldNode: FieldNode;
  variables?: { [variable: string]: unknown };
};

export function modifyFieldWithNode<T>({
  fieldNode,
  variables,
  ...options
}: ModifyFieldWithNodeOptions<T>) {
  const expectedStoreFieldName = storeKeyNameFromField(fieldNode, variables);
  if (!expectedStoreFieldName) return;
  return modifyField({
    ...options,
    expectedStoreFieldName,
    fieldName: fieldNode.name.value,
  });
}

type MakeModifyFieldWithNodeOptions = {
  fragmentName?: string;
  typename?: string;
};

export function makeModifyFieldWithNode(
  document: DocumentNode,
  fieldPath: string[],
  {
    fragmentName,
    typename: providedTypename,
  }: MakeModifyFieldWithNodeOptions = {},
) {
  if (fieldPath.length > 1 && !providedTypename) {
    throw new Error("Must provide a typename for nested field paths.");
  }
  const def = getOperationOrFragment(document, fragmentName);
  const fieldNode = getNodeAtPath(def, fieldPath);
  if (!fieldNode) throw new Error("Could not find node for provided path!");
  const typename =
    providedTypename ||
    (isFragmentDefinitionNode(def) ? def.typeCondition.name.value : undefined);
  return function preparedModifyFieldWithNode<T>(
    options: Omit<ModifyFieldWithNodeOptions<T>, "fieldNode" | "typename">,
  ) {
    return modifyFieldWithNode({ ...options, fieldNode, typename });
  };
}

export function makeConnectionFieldModifier(
  getNewNodes: Modifier<(Reference | undefined)[]>,
  numNewNodes?: number,
) {
  return function connectionFieldModifier(
    existing: NodesConnection<Reference | undefined>,
    details: ModifierDetails,
  ) {
    const { totalCount, nodes } = existing;

    if (!nodes) return existing;

    const newNodes = getNewNodes(nodes, details);

    const getNewTotalCount = () => {
      if (typeof totalCount !== "number") return totalCount;

      if (typeof numNewNodes === "number") {
        return totalCount + numNewNodes;
      }

      // Condense array to remove sparse elements and check if we have the same
      // number of elements as totalCount - only then can we imply the new
      // totalCount
      if (nodes.filter(() => true).length === totalCount) {
        const calcNumNewNodes = newNodes.length - nodes.length;
        return totalCount + calcNumNewNodes;
      }
    };

    return {
      ...existing,
      totalCount: getNewTotalCount(),
      nodes: newNodes,
    };
  };
}

export function objsToRefs(
  objs: StoreObject[],
  toReference: ToReferenceFunction,
) {
  return objs.map((obj) => toReference(obj));
}

export function makeConcatRefsModifierFactory(
  getNewRefs: (
    existingRefs: (Reference | undefined)[],
    newRefs: (Reference | undefined)[],
  ) => (Reference | undefined)[],
  defaultIsReversed: boolean,
) {
  return function concatRefsModifierFactory(
    newObjs: StoreObject[],
    isReversed = defaultIsReversed,
  ): Modifier<(Reference | undefined)[]> {
    return function prependRefsModifier(existing, { toReference }) {
      const newRefs = objsToRefs(newObjs, toReference);
      if (isReversed) newRefs.reverse();
      return getNewRefs(existing, newRefs);
    };
  };
}

export const appendRefs = makeConcatRefsModifierFactory(
  (existingRefs, newRefs) => [...existingRefs, ...newRefs],
  false,
);

export const prependRefs = makeConcatRefsModifierFactory(
  (existingRefs, newRefs) => [...newRefs, ...existingRefs],
  true,
);

export function removeRefs(
  removedObjs: StoreObject[],
): Modifier<(Reference | undefined)[]> {
  return function removeRefsModifier(existing, { toReference }) {
    const removedRefs = objsToRefs(removedObjs, toReference).map(
      (ref) => ref?.__ref,
    );

    // Use for loop instead of filter to maintain sparse arrays
    const newItems = existing.slice(0);
    for (let i = 0; i < existing.length; i += 1) {
      const ref = newItems[i];
      if (removedRefs.includes(ref?.__ref)) {
        newItems.splice(i, 1);
        i -= 1;
      }
    }

    return newItems;
  };
}

const UNDEFINED_CACHE_ID = "__undefined__";

type UnmodifiedFieldsMap = {
  [cacheId: string]: { [fieldName: string]: string[] };
};

export function clearUnmodifiedFields(
  cache: ApolloCache<unknown>,
  modifyResults: (ModifyFieldResult | undefined)[],
) {
  const map = modifyResults.reduce<UnmodifiedFieldsMap>((acc, result) => {
    if (!result) return acc;

    if (!acc[result.cacheId || UNDEFINED_CACHE_ID])
      acc[result.cacheId || UNDEFINED_CACHE_ID] = {};

    if (!acc[result.cacheId || UNDEFINED_CACHE_ID][result.fieldName]) {
      acc[result.cacheId || UNDEFINED_CACHE_ID][result.fieldName] = [];
    }

    if (result.isSuccess && result.expectedStoreFieldName) {
      acc[result.cacheId || UNDEFINED_CACHE_ID][result.fieldName].push(
        result.expectedStoreFieldName,
      );
    }

    return acc;
  }, {});

  Object.keys(map).forEach((cacheId) => {
    const fields = Object.keys(map[cacheId]).reduce<Modifiers>(
      (acc, fieldName) => {
        acc[fieldName] = (existing: unknown, details) => {
          if (!map[cacheId][fieldName].includes(details.storeFieldName)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return details.DELETE;
          }
          return existing;
        };
        return acc;
      },
      {},
    );

    return cache.modify({
      ...(cacheId !== UNDEFINED_CACHE_ID ? { id: cacheId } : {}),
      fields,
    });
  });
}

export type AddNewRecordsWithFieldSpecOptions = {
  baseFieldName: string;
  cache: ApolloCache<unknown>;
  createdAtEnumPrefix?: string;
  directionsTuple?: [isDesc: boolean, isDesc?: boolean];
  getArgs?: (
    defaultArgs: FieldSpecifier["args"],
    params: { isDesc: boolean; isOrderByArray: boolean },
  ) => FieldSpecifier["args"] | NonNullable<FieldSpecifier["args"]>[];
  id?: string;
  newObjs: StoreObject[];
  typename?: string;
};

export function addNewRecordsWithFieldSpec({
  baseFieldName,
  cache,
  createdAtEnumPrefix = "CREATED_AT",
  directionsTuple = [false, true],
  getArgs,
  id,
  newObjs,
  typename,
}: AddNewRecordsWithFieldSpecOptions) {
  const removed: (ModifyFieldResult | undefined)[] = [];

  directionsTuple.forEach((isDesc) => {
    if (typeof isDesc !== "boolean") return;

    const orderByEnum = isDesc
      ? `${createdAtEnumPrefix}_DESC`
      : `${createdAtEnumPrefix}_ASC`;

    [false, true].forEach((isOrderByArray) => {
      const defaultArgs: NonNullable<FieldSpecifier["args"]> = {
        orderBy: isOrderByArray ? [orderByEnum] : orderByEnum,
      };

      const argsArr = (() => {
        if (getArgs) {
          const maybeArrArgs = getArgs(defaultArgs, { isDesc, isOrderByArray });
          if (maybeArrArgs && !Array.isArray(maybeArrArgs))
            return [maybeArrArgs];
          return maybeArrArgs as FieldSpecifier["args"][];
        }
        return [defaultArgs];
      })();

      const modifier = isDesc ? prependRefs(newObjs) : appendRefs(newObjs);

      argsArr.forEach((args) => {
        removed.push(
          modifyFieldWithSpec({
            cache,
            fieldSpec: { fieldName: `${baseFieldName}List`, args },
            id,
            modifier,
            typename,
          }),
        );

        removed.push(
          modifyFieldWithSpec({
            cache,
            fieldSpec: { fieldName: baseFieldName, args },
            id,
            modifier: makeConnectionFieldModifier(modifier, newObjs.length),
            typename,
          }),
        );
      });
    });
  });

  clearUnmodifiedFields(cache, removed);
}

type RemoveDeletedRecordsOptions = {
  baseFieldName: string;
  cache: ApolloCache<unknown>;
  deletedRecords: StoreObject[];
  id?: string;
  typename?: string;
};

export function removeDeletedRecords({
  cache,
  baseFieldName,
  deletedRecords,
  id,
  typename,
}: RemoveDeletedRecordsOptions) {
  const results: ModifyFieldResult[] = [];

  const modifier = removeRefs(deletedRecords);

  results.push(
    modifyField({
      cache,
      fieldName: `${baseFieldName}List`,
      id,
      modifier,
      typename,
    }),
  );

  results.push(
    modifyField({
      cache,
      fieldName: baseFieldName,
      id,
      modifier: makeConnectionFieldModifier(modifier),
      typename,
    }),
  );

  cache.gc();

  return results;
}
