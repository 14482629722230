import { TypedDocumentNode } from "@apollo/client";
import {
  DefinitionNode,
  Kind,
  OperationDefinitionNode,
  FragmentDefinitionNode,
  SelectionNode,
  FieldNode,
  DocumentNode,
} from "graphql";

export function isOperationDefinitionNode(
  definition: DefinitionNode,
): definition is OperationDefinitionNode {
  return definition.kind === Kind.OPERATION_DEFINITION;
}

export function isFragmentDefinitionNode(
  definition: DefinitionNode,
): definition is FragmentDefinitionNode {
  return definition.kind === Kind.FRAGMENT_DEFINITION;
}

export function isFieldNode(
  definition: SelectionNode,
): definition is FieldNode {
  return definition.kind === Kind.FIELD;
}

/* eslint-disable import/prefer-default-export */
export function getOperationOrFragment(
  document: TypedDocumentNode,
  fragmentName?: string,
) {
  const operationDef = document.definitions.find(isOperationDefinitionNode);
  if (operationDef) return operationDef;
  const fragments = document.definitions.filter(isFragmentDefinitionNode);
  if (fragments.length === 0) {
    throw new Error("Could not find operation or fragment in document.");
  }
  if (!fragmentName) {
    if (fragments.length === 1) return fragments[0];
    throw new Error(
      "Must provide fragmentName when document contains multiple fragments.",
    );
  }
  const fragmentDef = fragments.find(
    (def) =>
      def.kind === Kind.FRAGMENT_DEFINITION && def.name.value === fragmentName,
  );
  if (!fragmentDef) {
    throw new Error(
      "Could not find fragment with provided name in provided document.",
    );
  }
  return fragmentDef;
}

export function getNodeAtPath(
  definition: OperationDefinitionNode | FragmentDefinitionNode,
  path: string[],
) {
  if (path.length === 0)
    throw new Error("Path must have at least one element.");
  return path.reduce<
    OperationDefinitionNode | FragmentDefinitionNode | FieldNode | undefined
  >(
    (prevNode, pathPart) =>
      prevNode?.selectionSet?.selections
        .filter(isFieldNode)
        .find((selection) =>
          selection.alias
            ? selection.alias.value === pathPart
            : selection.name.value === pathPart,
        ),
    definition,
  ) as FieldNode;
}

export function getFieldNodeFromDocument(
  document: DocumentNode,
  fieldPath: string[],
  fragmentName?: string,
) {
  const def = getOperationOrFragment(document, fragmentName);
  const node = getNodeAtPath(def, fieldPath);
  if (!node) throw new Error("Could not find node for provided path!");
  return node;
}
